'use strict';
jQuery(document).ready(function($){

   
    $('.home-slider').slick({
        infinite: true,
        autoplay: true,
        fade: true,
        arrows:false,
        dots:true,
        pauseOnHover: false
      });
});